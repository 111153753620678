.Content {
  width: 300px;
}

.Header {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.Body {
  padding: 9px 14px;
}

.Footer {
  display: flex;
  justify-content: space-evenly;
  padding: 9px 14px;
}


