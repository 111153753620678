.Title {
  flex-grow: 1;
  color: #fff;
  cursor: pointer;
}

.AppBar {
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

}

.AppBarColor {
  color: #fff;
  background-color: #6163FF;
}

.AppBarShift {
  z-index: 1201;
  width:calc(100% - 240px);
  margin-left: 240px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.Toolbar {
  padding-right: 24px;
}

.MenuButton {
  margin-right: 36px;
}

.MenuButtonHidden {
  display: none;
}

.Container {
  display: flex;
  justify-content: flex-end;
  /*align-content: space-between;*/

}

.Logo {
  padding-top: 10px;
}
