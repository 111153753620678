.Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  justify-content: center;
}


.Visualizer {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 400px;
  height: 400px;
  padding: 4px;
  box-sizing: border-box;
}

.Internal {
  display: flex;
  min-width: 0;
  overflow: hidden
}
