.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:  auto;
}

.Container {
  /*background-color: #fff;*/
  /*border: 2px solid #000;*/
  padding: 16px 32px 24px;
  /*box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 5px 8px 0 rgba(0,0,0,0.14),0 1px 14px 0 rgba(0,0,0,0.12);*/
}


.closeButton {
  position: absolute;
  color: #9e9e9e;
  top: 8px;
  right: 8px;

}
.helpButton {
  position: absolute;
  color: #9e9e9e;
  top: 8px;
  right: 40px;

}
