.Container {
  padding-bottom: 20px;
  flex-grow: 1;
}


.Card {
  min-width: 280px;
  max-width: 350px;
}


@media only screen and (min-width: 480px) {

  .Card {
    margin-left: 25px;
  }
}

