.Box {
  padding: 10px;
}

.Card {
  min-width: 280px;
  max-width: 350px;
  cursor: pointer;
}


.CardColor {
  background-color: #D0E4F5;
}

.BoxTitle {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.DetailTitle {

}

.NegativeBox{
  height: 150px;

}

.NegativeText {
  padding-top: 50px;
  text-align: center;
}
