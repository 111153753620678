.Root {
  flex-grow: 1;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.RootCard {
  flex-grow: 1;
  text-align: center;
  padding-bottom: 5px;
}

.Card {
  background-color:  #25282C;
  color:  #9CA9B3;
  padding: 32px;
}


.Separator {
  content: '';
  /*position: absolute;*/
  margin-top: 20px;
  margin-bottom: 15px;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #33363A;
}

.Text {
  color: #9CA9B3;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Inter", sans-serif;
}

.Title {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #ECEDED;
  font-weight: 800;
  font-size: 2.5rem;
  font-family: "Inter", sans-serif;
}

.MinorText {
  color: #9CA9B3;
  font-weight: 400;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
  text-align: start;
}

.FooterText {
  color: #9CA9B3;
  font-weight: 600;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  text-align: start;
}


.Green {
  color: #73EFCC
}

.White {
  color: #ECEDED
}
