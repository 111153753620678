

.Box {
  padding-top: 80px;
}

.PrimaryColor {
  color: #5658DD
}

.TitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 10px;
}

.Title {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #2E3458;
  font-weight: 800;
  font-size: 3rem;
  font-family: "Inter", sans-serif;

}

.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.Root {
  flex-grow: 1;
  text-align: center;
}

.Video {
  margin-top: 48px;
}
.Paper {
  background-color: #fff;
  border: 2px solid #000;
  padding: 16px 32px 24px;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 5px 8px 0 rgba(0,0,0,0.14),0 1px 14px 0 rgba(0,0,0,0.12);;
}


.Actions {
  padding-top: 10px;
}

.Modal {
  background-color: #fff;
}

.GoBack {
  padding-top: 20px;
  text-align: right;
}

.GoBackText {
  color: #575E86;
  /*padding-right: 15px;*/
  /*padding-left: 15px;*/
}

