.Root {
  flex-grow: 1;

}


.GroupItems {
  display: flex;
  justify-content: flex-end;
  fill: #6163FF;
}

.Itens {
  padding-right: 15px;
  padding-left: 15px;


}

.GroupItems a:hover svg {
  fill: #ACADFF;
  transition: fill .15s ease;
}
