.Box {
  padding-top: 80px;

}

.Root {
  flex-grow: 1;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.VisualRoot {
  flex-grow: 1;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.InternalBox {
  flex-grow: 1;
  text-align: justify;
  /*padding-bottom: 80px;*/
}

.Separator {
  content: '';
/*position: absolute;*/
  margin-bottom: 40px;
top: 0;
left: 0;
width: 100%;
display: block;
height: 1px;
background: #25282C;
}

.Title {
  margin-top: 16px;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #ECEDED;
  font-weight: 800;
  font-size: 2.5rem;
  font-family: "Inter", sans-serif;

}

.Text {
  color: #575E86;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Inter", sans-serif;
}


.PreTitle {
  color: #5658DD;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 8px;
  /*font-family: "Inter", sans-serif;*/
}

.MinorTitle {
  color: #2E3458;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  margin-bottom: 16px;
}
