

.Form {
  width: 100%;
}

.Item {
  text-align: left;
}

.Button {
  text-align: center;

}
.Select {
  text-align: left;
}

.Switch {
  display: flex;
  justify-content: center;
}
