.Color {
  color:white
}




.Text {
  padding-top: 15px;
  padding-left: 15px;

}

.NegativeBox{
  height: 150px;

}

.NegativeText {
  padding-top: 50px;
  text-align: center;
}
