

.Box {
  padding-top: 80px;
}

.PrimaryColor {
  color: #5658DD
}

.TitleContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.Title {

  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #2E3458;
  font-weight: 800;
  font-size: 2.5rem;
  font-family: "Inter", sans-serif;
}

 .MinorTitle {
   color: #2E3458;
   font-weight: 800;
   font-size: 1.5rem;
   font-family: "Inter", sans-serif;
   margin-bottom: 16px;
 }

.Text {
  color: #575E86;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Inter", sans-serif;
}
.MinorText {
  color: #575E86;
  font-weight: 400;
  font-size: 1.1rem;
  font-family: "Inter", sans-serif;
}

.Root {
  flex-grow: 1;
  text-align: center;
  padding-bottom: 10px;
}

.Separator {
  content: '';
  margin-bottom: 40px;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #25282C;
}

/*.Root:last-of-type {*/
/*  padding-bottom: 80px;*/
/*}*/



.Video {
  margin-top: 48px;
}

.Icon {
  border-radius: 50%;
  background-color: #5658DD;
  color: white;
  width: 64px;
  height: 64px;
  display: inline-block;
  margin-bottom: 20px;
  padding-top: 12px;
}

.Card {
  background-color:  transparent;
  color:  #9CA9B3;
  padding: 32px;
}


