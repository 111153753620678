.Input {
  background-color: #ECEDED;
  width: 100%;
}


.IconButton {
  padding: 0;
}

.Field {
  margin-top: 10px;
  /*min-width: 150px;*/
}


