.Title {
  flex-grow: 1;
  color: #2E3458;
  text-align: left;
}


.Logo {
  padding-top: 10px;
}

.Container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ContainerLogo {
  min-width: 50px;
  width: 6%;
}


.ContainerTitle {
  width: 94%;
}


