.Root {
  flex-grow: 1;
}


.GroupItems {
  display: flex;
  justify-content: flex-end;
}

.Itens {
  color: #575E86;
  padding-right: 15px;
  padding-left: 15px;
  text-decoration: none;
  cursor: pointer;
}

.GroupItems a:hover {
  color: #2E3458;

}

