.Box {
  padding-top: 30px;
}

.Root {
  flex-grow: 1;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Text {
  color: #575E86;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Inter", sans-serif;

}

.Link {
  text-decoration: none;
  color: #2E3458;
}

.Link a:visited {
  color: #2E3458;
}
