.Button {
  text-align: center;
}

.Container {
  padding-top: 30px;
}


.MajorTitle {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #2E3458;
  font-weight: 800;
  font-size: 3rem;
  font-family: "Inter", sans-serif;

}

.MajorTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 30px;
}


.Title {
  font-size: 0.9375rem;
  flex-basis: 33.33%;
  flex-shrink: 0;
}

.Subtitle {
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.54);
}
