.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.Plan {
  padding-left: 10px;
  padding-top: 10px;
}



