.Button {
  text-align: center;
}

.CardAlign {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.Root {
  flex-grow: 1;
  text-align: center;
  padding-top: 80px;
}

.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.List {
  font-family: "Inter", sans-serif;
}

.Packages {
  margin-top: 40px;
}

.Price {
  height: 120px;
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ECEDED;
  font-family: "Inter", sans-serif;
}



.PriceMonth {
  background-color: #25282C;
}

.PriceAnnual {
  background-color: #6b6dff;
}

.SwitchOn {
  color: #6b6dff;
  opacity: 1;
}

.SwitchOff {
  background-color: #25282C;
  opacity: 1;
}

.SwitchButton {
  background-color: #ECEDED;
}

.ListItem {
  justify-content: center;
}


.Ribbon span {
  position: absolute;
  display: block;
  width: 160px;
  padding: 10px 0;
  background-color: #25282C;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 0.95rem;
  text-transform: uppercase;
  text-align: center;
  right: -35px;
  top: 25px;
  transform: rotate(45deg);
}

.Ribbon::before, .Ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
  border-top-color: transparent;
  border-left-color: transparent;
}

.Ribbon::before {
  top: 0;
  right: 0;
}

.Ribbon::after {
  bottom: 0;
  left: 0;
}

.Plan {
  padding-left: 10px;
  padding-top: 10px;
}

.MyText {
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
