.Title {
  font-size: 0.9375rem;
  flex-basis: 33.33%;
  flex-Shrink: 0;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #575E86;
}

.SubTitle {
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Inter", sans-serif;
}

.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.TotalTitle {
  font-size: 1rem;
  flex-Shrink: 0;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #575E86;
}
