
.Form {
  width: 102%;
}

.Item {
  text-align: center;
}

.BusinessBox {
  border-radius: 8px;
  overflow-y: auto;
  max-height: 30vh;
}


.OperationBox {
  margin-bottom: 15px;

}

.Button {
  text-align: center;
}

.IncOpButton {
  text-align: center;
  margin-top: 16px;
}

.RemoveOpButton {
  text-align: left;
  margin-top: 16px;
}


.Main {
  min-height: 30vh;
  min-width: 50vmin;
}

.Item2 {
  text-align: center;
  height: 220px;
  width: 220px;
  cursor: pointer;
}

.Grid {
  display: flex;
  justify-content: center;
}

.FixedFields {
  /*background-color: #9CA9B3;*/
  padding-bottom: 10px;
}

.DataContainer {
  height: 30vh;
  border-color:#9e9e9e;
  border-style: inset;
  overflow-y: auto;
  padding: 10px
}

.InternalContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  padding-right: 16px;
}

.Alert {
  color:#ff122b;
}

.Badge {
  color: #231E39;
  background-color: #0b95fe;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  width: 150px;
  text-align: center;
  white-space: nowrap;
  margin-left: 33px;

}


.Box {
  display: flex;
  justify-content: center;
}
