.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-Wrap: break-word;
  min-height: 120px;
  width: 340px;
}

.Dates {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 2px;
}

.Separator {
  content: '';
  /*;*/
  margin-bottom: 20px;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #e2e8f0;
}

.SubTitle {
  font-size: 1.2em;
  font-weight: bold;
}

.SubTitleBox {
 display: flex;
 justify-content: space-between;
}

.MainText {
  font-size: 2.2em;
  text-align: center;
}

.Text {
  font-size: 1.2em;
  /*white-space: nowrap*/
  /*text-align: center;*/
}

.Title {
  display: flex;
  font-size: 1.4em;
}

.Paper {
  color: #231E39;
  background-color: #FEBB0B;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  width: 80px;
  white-space: nowrap
}






