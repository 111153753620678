.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-Wrap: break-word;
  min-height: 120px;
  min-width: 356px;
}

.Title {
  display: flex;
  font-size: 1.75em;
}


.Avatar {
  width: 56px;
  height: 56px;
  background: #2a2d32;
}

.Text{
  font-size: 2.2em;
  text-align: center;
}
