.Button {
  text-align: center;
}

.Plan {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}


.BoxMessageSuccess {
  padding: 10px;
  background-color: chartreuse;
  font-family: "Inter", sans-serif;
}

.BoxMessageError {
  padding: 10px;
  background-color: crimson;
  color: #fafafa;
  /*background-color: chartreuse;*/
  font-family: "Inter", sans-serif;
}

.MessageContainer {
  margin-left: 10px;
  margin-right: 10px;
}
