.Button {
  margin-top: 10px;
  /*display: flex;*/
  /*align-items: center;*/
}

.Header {
  background-color: #e8e8e5;
  min-height: 100px;
  min-width: 180px;
  margin-bottom: 10px;
}
.Search {
  padding: 10px;
}

