.Box {
  padding-top: 80px;
}

.Button {
  text-align: right;
}
.PrimaryColor {
  color: #5658DD
}

.Root {
  flex-grow: 1;
  text-align: center;
}

.Text {
  color: #575E86;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.TitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 10px;
}

.Title {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #2E3458;
  font-weight: 800;
  font-size: 3rem;
  font-family: "Inter", sans-serif;
}
