.Button {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 6px;
  padding: 11px 31px;
  text-transform: none;
  text-decoration: none !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  cursor: pointer;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background .15s ease;

}

.Button:active {
  outline: 0;
}

.Button:hover {
  background-color: #dfe1e1;
  border-color: transparent;
}

.Button.is-loading {
  position: relative;
  color: transparent !important;
  pointer-events: none;
}


.Primary {
  color: #ECEDED;
  background-color: #6163FF;
  border-color: transparent;
}

.Primary:hover {
  background-color: #6b6dff;
  border-color: transparent;
}

.Primary.is-loading::after {
  border-color: transparent;
}

.Dark {
  color: #ECEDED;
  background-color: #25282C;
  border-color: transparent;
}

.Dark:hover {
  background-color: #2a2d32;
  border-color: transparent;
}

.Dark.is-loading::after {
  border-color: transparent;
}

.Transparent {
  color: #ECEDED;
  background-color: transparent;
  border-color: transparent;
}

.Transparent:hover {
  background-color: #2a2d32;
  border-color: transparent;
}

.Transparent.is-loading::after {
  border-color: transparent;
}
