.Card {
  /*background-color:  #25282C;*/
  color:  #9CA9B3;
  padding: 32px;
}

.Button {
  text-align: center;

}

.Title{
  text-align: center;
  /*margin-left: 20px;*/
}


.Item {
  text-align: center;
}

.Form {
  width: 100%;
}

.Field {
  margin: 10px;
}

.Therms {
  padding-left: 10px;
  height: 200px;
  overflow: auto;
}

.ThermsText {
  text-align: justify;
}

.MessageBox{
padding-top: 150px;
  text-align: center;
}

.TitleBox {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  /*color: #ECEDED;*/
  font-weight: 700;
  font-size: 2rem;
  font-family: "Inter", sans-serif;
}
