
.Container {
  padding-bottom: 10px;
}

@media screen and (min-width: 1000px) {
  .Button{
    margin-top: 7px;
  }

}



@media screen and (max-width: 1000px) {
  .Button{
    margin-bottom: 20px;
  }

}
