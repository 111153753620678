
.Main {
  min-height: 35vh;
  min-width: 50vmin;
}


.Title {
  color:#ff122b;
}

.Notice {
  text-align: center;
}






