.Box {
  padding-top: 40px;
}

.Root {
  flex-grow: 1;
  /*text-align: center;*/
  background-color: #5658DD;
  padding: 64px 25px;
}

.Input {
  background-color: #ECEDED;
  padding: 2px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}

.Text {
  color: #9CA9B3;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Inter", sans-serif;
}

.Title {
  margin-top: 0;
  margin-bottom: 16px;
  transform: translateY(10px);
  color: #ECEDED;
  font-weight: 700;
  font-size: 2rem;
  font-family: "Inter", sans-serif;
}
