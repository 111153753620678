.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:  auto;
}

.Container {

  padding: 16px 32px 24px;

}


